import React from "react";
import clsx from "clsx";
import { DataRow } from "../components/Stock";
import ExportButtons from "../components/Stock/ExportButtons";
import { strings } from "common";
import { cells, emptyCells, orphanedTabcells } from "../components/Stock/cells";
import TableSkeleton from "../components/Table/Skeleton";
import { useCasesSearch, usePhysicalRecordSearch } from "../utils/cases";
import { caseStatus } from "../constants";
import { useLocation } from "react-router";
import { Box, makeStyles, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { StateSchema } from "../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.grey.A100,
  },
  spinner: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: theme.spacing(8),
  },
}));

const filterValues = [...Object.keys(caseStatus)];

const firstColspan = 2;

const Stock = () => {
  const classes = useStyles();
  const location = useLocation();

  let initFilter;

  if (filterValues.includes((location?.state as StateSchema)?.status)) {
    initFilter = (location.state as StateSchema).status;
  }

  const [tab, setTab] = React.useState(0);

  const handleChange = (_: object, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Typography variant="h4">{strings.stock}</Typography>

      <Box marginTop={8}>
        <Paper>
          <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="tabs">
            <Tab
              aria-label="stockTable"
              className={clsx({ [classes.selected]: tab === 0 })}
              label={strings.assetUnits}
            />
            <Tab
              aria-label="stockTable"
              className={clsx({ [classes.selected]: tab === 1 })}
              label={strings.orphanStock}
            />
          </Tabs>
        </Paper>
      </Box>

      <Box marginTop={-8}>
        {tab === 0 ? (
          <TableSkeleton
            dense
            name="stock"
            query={useCasesSearch}
            cells={cells}
            emptyCells={emptyCells}
            filterParam={"statuses"}
            filterValues={filterValues}
            firstColspan={firstColspan}
            initFilter={initFilter}
            DataRow={DataRow}
            ExportComponent={ExportButtons}
          ></TableSkeleton>
        ) : (
          <TableSkeleton
            dense
            name="orphanStock"
            query={(defaultParams) => {
              return usePhysicalRecordSearch({
                ...defaultParams,
                linked: false,
              });
            }}
            cells={orphanedTabcells}
            emptyCells={emptyCells}
            filterParam={"status"}
            filterValues={filterValues}
            firstColspan={firstColspan}
            initFilter={initFilter}
            DataRow={DataRow}
            ExportComponent={ExportButtons}
          />
        )}
      </Box>
    </>
  );
};

export default Stock;

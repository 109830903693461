import React, { Dispatch, SetStateAction } from "react";
import {
  makeStyles,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Typography,
  Grid,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { MainButton } from "../Common";
import ExtraInfo from "./ExtraInfo";
import { Formik } from "formik";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { exportAddresses, transferOrderAddresses, underbondAddresses } from "../Orders/shipperaddresses";
import { countries } from "../Orders/countries";

import { strings, regexes, constants } from "common";
import { DateTime } from "luxon";
import { CreateSaleOrderDataSchema, DeliveryCountrySchema, ShipSchema } from "../../interfaces/case.interface";
import { orderDateFormat } from "../../constants";
import { HARDCODED_WAREHOUSES_VALUES } from "../../utils/warehouses";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 9),
  },
  info: {
    display: "flex",
    alignItems: "center",
    background: "#C5E4FC",
    borderRadius: "40px",
    height: "45px",
    padding: theme.spacing(7),
  },
  inputs: {
    display: "flex",
    marginBottom: theme.spacing(8),
  },
  input: {
    width: "95%",
    marginRight: theme.spacing(8),
  },
  backButton: {
    marginRight: theme.spacing(10),
    background: "white",
    color: "grey",
  },
  select: {
    width: "434px",
    height: "34px",
  },
  filledInfo: {
    padding: theme.spacing(0, 8, 8, 8),
    background: "#FAFAFA",
    borderRadius: "16px",
    marginBottom: theme.spacing(8),
  },
  addressSummary: {
    marginBottom: theme.spacing(4),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  cellName: {
    marginTop: theme.spacing(8),
    fontSize: "12px",
    color: "#1D4854",
    fontWeight: "bold",
  },
  label: {
    color: "#1D4854",
    fontSize: "16px",
    fontWeight: "bold",
  },
  selectLabel: {
    color: "#1D4854",
    fontSize: "12px",
    fontWeight: "bold",
  },
  errorMessage: {
    color: "#F44336",
  },
  gridStyle: {
    marginRight: theme.spacing(2),
  },
}));

const bondTypes = [
  {
    label: strings.duty_paid,
    value: "P",
    disabled: false,
  },
  {
    label: strings.northern_ireland_duty_paid,
    value: "N",
    disabled: false,
  },
  {
    label: strings.uk_underbond,
    value: "U",
    disabled: false,
  },
  {
    label: strings.ec_export,
    value: "E",
    disabled: false,
  },
  {
    label: strings.non_ec_export,
    value: "X",
    disabled: false,
  },
  {
    label: strings.transfer_order,
    value: "T",
    disabled: false,
  },
];

const receivingCustomerCodeMap = [
  {
    name: "Drakelow",
    value: "CULTKD",
  },
  {
    name: "MELKSHAM",
    value: "CULTMM",
  },
];

const receivingCustomerCodeOptions = receivingCustomerCodeMap.map((item) => {
  return item.value;
});

const selectableDeliveryBondTypes = ["X", "U", "E"];

const validationSchema = Yup.object().shape({
  bondType: Yup.string().required(strings.required),
  fkCustomer: Yup.string()
    .required(strings.required)
    .oneOf(HARDCODED_WAREHOUSES_VALUES.map((w) => w.warehouseVisionCode)),
  dpAccountRef: Yup.string().required(strings.required),
  deliveryName: Yup.string()
    .required(strings.required)
    .max(constants.visionEdiLimits.mediumInputLength, strings.value_too_long),
  deliveryAddress1: Yup.string().required(strings.required).nullable(),
  deliveryAddress2: Yup.string().when("bondType", (bondType, schema) => {
    return bondType === "T" ? schema.required(strings.required) : schema;
  }),
  deliveryTownOrCounty: Yup.string()
    .required(strings.required)
    .min(4, strings.value_too_short)
    .max(constants.visionEdiLimits.mediumInputLength, strings.value_too_long),
  deliveryCountry: Yup.string()
    .required(strings.required)
    .matches(regexes.twoDigitCountryCode, strings.invalid_country_code),
  deliveryPostcode: Yup.string().matches(regexes.basicPostalCode, strings.invalid_postcode),
  contactPhoneNumber: Yup.string()
    .matches(regexes.phone, strings.invalid_phone_number)
    .when("contactName", (contactName, schema) => {
      return contactName ? schema.required(strings.provide_phone_number) : schema;
    }),
});

interface GeneralInformationProps {
  bondType: string;
  setBondType: (val: string) => void;
  handleNext: () => void;
  data: CreateSaleOrderDataSchema;
  setData: Dispatch<SetStateAction<CreateSaleOrderDataSchema>>;
  shipper: ShipSchema | null;
  setShipper: any;
  deliveryCountry: DeliveryCountrySchema | undefined;
  setDeliveryCountry: React.Dispatch<React.SetStateAction<DeliveryCountrySchema | undefined>>;
}

const GeneralInformation = ({
  bondType,
  setBondType,
  handleNext,
  data,
  setData,
  shipper,
  setShipper,
  deliveryCountry,
  setDeliveryCountry,
}: GeneralInformationProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Formik
        initialValues={{
          fkCustomer: data.warehouseVisionCode || "",
          dpAccountRef: data.dpAccountRef || "",
          orderDate: DateTime.fromJSDate(new Date()).toFormat(orderDateFormat),
          address: data.address || "",

          deliveryName: data.deliveryName || "",
          deliveryAddress1: data.deliveryAddress1 || "",
          deliveryAddress2: data.deliveryAddress2 || "",
          deliveryAddress3: data.deliveryAddress3 || "",
          deliveryTownOrCounty: data.deliveryTownOrCounty || "",
          deliveryPostcode: data.deliveryPostcode || "",
          deliveryCountry: data.deliveryCountry || deliveryCountry?.Code,

          contactName: data.contactName || "",
          contactPhoneNumber: data.contactPhoneNumber || "",
          deliveryEntityName: data.deliveryEntityName || "",
          bondType: bondType || "",
          hasExceptAddress: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setData((prev: CreateSaleOrderDataSchema) => {
            return { ...prev, ...values };
          });
          handleNext();
        }}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue, setValues }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.inputs}>
              <TextField
                className={classes.input}
                label={strings.fk_customer}
                name="fkCustomer"
                onChange={handleChange}
                value={values.fkCustomer}
                error={Boolean(errors?.fkCustomer)}
                disabled
              />

              <div>
                <InputLabel id="select-label" className={classes.selectLabel}>
                  {strings.bond_type} *
                </InputLabel>
                <Select
                  labelId="select-label"
                  name="bondType"
                  className={`${classes.input} ${classes.select}`}
                  value={bondType}
                  onChange={(e) => {
                    const oldBondType = bondType;
                    const newBondType: string = typeof e.target.value === "string" ? e.target.value : "";
                    setBondType(newBondType);
                    let newData = { ...values };
                    if (
                      selectableDeliveryBondTypes.includes(newBondType) ||
                      selectableDeliveryBondTypes.includes(oldBondType)
                    ) {
                      newData = {
                        ...values,
                        deliveryName: "",
                        deliveryAddress1: "",
                        deliveryAddress2: "",
                        deliveryAddress3: "",
                        deliveryTownOrCounty: "",
                        deliveryPostcode: "",
                        deliveryCountry: "",
                        dpAccountRef: "",
                      };
                      setDeliveryCountry && setDeliveryCountry(undefined);
                      setShipper(null);
                    }
                    newData.bondType = newBondType;
                    setValues(newData);
                  }}
                  error={Boolean(errors?.bondType)}
                >
                  {bondTypes.map((el) => (
                    <MenuItem key={el.value} value={el.value} disabled={el.disabled}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors?.bondType}</FormHelperText>
              </div>
            </div>
            <div className={classes.inputs}>
              <TextField
                className={classes.input}
                label={strings.dp_account_reference}
                name="dpAccountRef"
                onChange={handleChange}
                value={values.dpAccountRef}
                error={Boolean(errors?.dpAccountRef)}
                helperText={values?.dpAccountRef}
                InputLabelProps={{
                  classes: { shrink: classes.label },
                }}
                inputProps={{ maxLength: 30 }}
              />
              <TextField
                className={classes.input}
                label={strings.order_date}
                name="orderDate"
                onChange={handleChange}
                value={values.orderDate}
                error={Boolean(errors?.orderDate)}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </div>
            {bondType !== "T" ? (
              <ExtraInfo
                data={
                  bondType === "U" || bondType === "X" || bondType === "E"
                    ? "Enter here the name and address of receiving warehouse or shipper where that is considered the export point"
                    : "Enter here the name and address of the delivery point (e.g. person or company receiving the goods)"
                }
              />
            ) : (
              <TextField
                className={classes.inputs}
                label={strings.address + "*"}
                name="address"
                onChange={handleChange}
                value={values.address}
                error={Boolean(errors?.address)}
                fullWidth
                disabled
                InputLabelProps={{
                  classes: { shrink: classes.label },
                }}
              />
            )}
            {["U", "X", "T", "E"].includes(bondType) ? (
              <div>
                <Autocomplete
                  id="shipper-dropdown"
                  options={(bondType === "T"
                    ? transferOrderAddresses
                    : bondType === "U"
                    ? underbondAddresses
                    : exportAddresses
                  ).sort((a, b) => a.dropdownName.localeCompare(b.dropdownName))}
                  getOptionLabel={(option) => option.dropdownName || ""}
                  value={shipper}
                  onChange={(_, option) => {
                    setShipper(option);
                    const {
                      deliveryName,
                      deliveryAddress1,
                      deliveryAddress2,
                      deliveryAddress3,
                      deliveryTownOrCounty,
                      deliveryPostcode,
                      deliveryCountry,
                      dpAccountRef,
                    } = option || {};

                    let _deliveryAddress1 = deliveryAddress1;
                    let _deliveryName = deliveryName;
                    let _deliveryAddress2 = deliveryAddress2;
                    let _deliveryAddress3 = deliveryAddress3;
                    let _hasExceptAddress = false;
                    if (bondType === "T") {
                      _deliveryName = "";
                      _deliveryAddress1 = "";
                      _deliveryAddress2 = "";
                      _deliveryAddress3 = "";
                      if (
                        receivingCustomerCodeMap[0] &&
                        new RegExp(receivingCustomerCodeMap[0].name).test(deliveryAddress1 ? deliveryAddress1 : "")
                      ) {
                        _deliveryAddress1 = receivingCustomerCodeMap[0]?.value;
                        _hasExceptAddress = true;
                      }
                      if (
                        receivingCustomerCodeMap[1] &&
                        new RegExp(receivingCustomerCodeMap[1].name).test(deliveryTownOrCounty ?? "")
                      ) {
                        _deliveryAddress1 = receivingCustomerCodeMap[1]?.value;
                      }
                    }
                    setValues({
                      ...values,
                      deliveryName: _deliveryName ? _deliveryName : "",
                      deliveryAddress1: _deliveryAddress1 ? _deliveryAddress1 : "",
                      deliveryAddress2: _deliveryAddress2 ? _deliveryAddress2 : "",
                      deliveryAddress3: _deliveryAddress3 ? _deliveryAddress3 : "",
                      deliveryTownOrCounty: deliveryTownOrCounty ? deliveryTownOrCounty : "",
                      deliveryPostcode: deliveryPostcode ? deliveryPostcode : "",
                      deliveryCountry,
                      dpAccountRef: option != null ? (dpAccountRef ? dpAccountRef : "") : "",
                      hasExceptAddress: _hasExceptAddress,
                    });
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} label="Receiving warehouse or shipper" variant="outlined" />
                  )}
                />
                <Grid container>
                  <Grid item xs={4}>
                    <div>
                      <TextField
                        className={classes.input}
                        label={strings.receiving_stock_holding_customers_name}
                        name="deliveryName"
                        onChange={handleChange}
                        value={values.deliveryName}
                        error={Boolean(errors?.deliveryName)}
                        helperText={strings.delivery_name}
                        InputLabelProps={{
                          classes: { shrink: classes.label },
                        }}
                        inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <>
                      <Autocomplete
                        className={classes.input}
                        freeSolo
                        autoSelect
                        options={receivingCustomerCodeOptions}
                        value={values.deliveryAddress1}
                        onChange={(_e, value) => {
                          setFieldValue("deliveryAddress1", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={strings.receiving_customer_code}
                            error={Boolean(errors?.deliveryAddress1)}
                            helperText={strings.delivery_address_line_1}
                            inputProps={{
                              ...params.inputProps,
                              maxLength: constants.visionEdiLimits.mediumInputLength,
                            }}
                          />
                        )}
                      />
                    </>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.input}
                      label={strings.receiving_customers_site_code}
                      name="deliveryAddress2"
                      onChange={handleChange}
                      value={values.deliveryAddress2}
                      error={Boolean(errors?.deliveryAddress2)}
                      helperText={strings.delivery_address_line_2}
                      InputLabelProps={{
                        classes: { shrink: classes.label },
                      }}
                      inputProps={{
                        maxLength: constants.visionEdiLimits.mediumInputLength,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={classes.input}
                      label={strings.reserve_codes}
                      name="deliveryAddress3"
                      onChange={handleChange}
                      value={values.deliveryAddress3}
                      error={Boolean(errors?.deliveryAddress3)}
                      helperText={strings.delivery_address_line_3}
                      InputLabelProps={{
                        classes: { shrink: classes.label },
                      }}
                      inputProps={{
                        maxLength: constants.visionEdiLimits.mediumInputLength,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={3}>
                    <Typography className={classes.cellName}>
                      {bondType === "T" ? strings.town : strings.delivery_town_county}
                    </Typography>
                    <TextField
                      className={classes.input}
                      name="deliveryTownOrCounty"
                      onChange={handleChange}
                      value={values.deliveryTownOrCounty}
                      error={Boolean(errors?.deliveryTownOrCounty)}
                      helperText={strings.delivery_town_county}
                      InputLabelProps={{
                        classes: { shrink: classes.label },
                      }}
                      inputProps={{
                        maxLength: constants.visionEdiLimits.mediumInputLength,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.cellName}>
                      {bondType === "T" ? strings.postcode : strings.delivery_postcode}
                    </Typography>
                    <TextField
                      className={classes.input}
                      name="deliveryPostcode"
                      onChange={handleChange}
                      value={values.deliveryPostcode}
                      error={Boolean(errors?.deliveryPostcode)}
                      helperText={strings.delivery_postcode}
                      InputLabelProps={{
                        classes: { shrink: classes.label },
                      }}
                      inputProps={{
                        maxLength: 15,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.cellName}>
                      {bondType === "T" ? strings.country : strings.delivery_country}
                    </Typography>
                    <TextField
                      className={classes.input}
                      name="deliveryCountry"
                      onChange={handleChange}
                      value={values.deliveryCountry}
                      error={Boolean(errors?.deliveryCountry)}
                      helperText={strings.delivery_country}
                      InputLabelProps={{
                        classes: { shrink: classes.label },
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <>
                <div className={classes.inputs}>
                  {
                    <TextField
                      className={classes.input}
                      name="deliveryName"
                      label={strings.delivery_name}
                      onChange={handleChange}
                      value={values.deliveryName}
                      error={Boolean(errors?.deliveryName)}
                      helperText={values?.deliveryName}
                      inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                      InputLabelProps={{
                        classes: { shrink: classes.label },
                      }}
                      disabled={!bondType}
                    />
                  }
                  <TextField
                    className={classes.input}
                    label={strings.delivery_address_line_1}
                    name="deliveryAddress1"
                    onChange={handleChange}
                    value={values.deliveryAddress1}
                    error={Boolean(errors?.deliveryAddress1)}
                    helperText={values?.deliveryAddress1}
                    inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                    disabled={!bondType}
                  />
                  <TextField
                    className={classes.input}
                    label={strings.delivery_address_line_2}
                    name="deliveryAddress2"
                    onChange={handleChange}
                    value={values.deliveryAddress2}
                    error={Boolean(errors?.deliveryAddress2)}
                    inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                    disabled={!bondType}
                  />
                  <TextField
                    className={classes.input}
                    label={strings.delivery_address_line_3}
                    name="deliveryAddress3"
                    onChange={handleChange}
                    value={values.deliveryAddress3}
                    error={Boolean(errors?.deliveryAddress3)}
                    inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                    disabled={!bondType}
                  />
                </div>
                <div className={classes.inputs}>
                  <TextField
                    className={classes.input}
                    label={strings.delivery_town_county}
                    name="deliveryTownOrCounty"
                    onChange={handleChange}
                    value={values.deliveryTownOrCounty}
                    error={Boolean(errors?.deliveryTownOrCounty)}
                    helperText={values?.deliveryTownOrCounty}
                    inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                    disabled={!bondType}
                  />
                  <TextField
                    className={classes.input}
                    label={strings.delivery_postcode}
                    name="deliveryPostcode"
                    onChange={handleChange}
                    value={values.deliveryPostcode}
                    error={Boolean(errors?.deliveryPostcode)}
                    helperText={values?.deliveryPostcode}
                    inputProps={{ maxLength: constants.visionEdiLimits.shortInputLength }}
                    InputLabelProps={{
                      classes: { shrink: classes.label },
                    }}
                    disabled={!bondType}
                  />
                  <Autocomplete
                    id="delivery-country-dropdown"
                    value={deliveryCountry}
                    onChange={(_e, option) => {
                      setDeliveryCountry && setDeliveryCountry(option == null ? undefined : option);
                      setFieldValue("deliveryCountry", option?.Code);
                    }}
                    options={countries}
                    className={classes.input}
                    getOptionLabel={(option) => option.Name}
                    disabled={!bondType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={strings.delivery_country}
                        variant="outlined"
                        error={Boolean(errors?.deliveryCountry)}
                        helperText={values?.deliveryCountry}
                      />
                    )}
                  />
                </div>
              </>
            )}
            <div className={classes.inputs}>
              <TextField
                className={classes.input}
                label={strings.contact_name}
                name="contactName"
                onChange={handleChange}
                value={values.contactName}
                error={Boolean(errors?.contactName)}
                inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                InputLabelProps={{
                  classes: { shrink: classes.label },
                }}
              />
              <TextField
                className={classes.input}
                label={strings.contact_phone_number}
                name="contactPhoneNumber"
                onChange={handleChange}
                value={values.contactPhoneNumber}
                error={Boolean(errors?.contactPhoneNumber)}
                helperText={values?.contactPhoneNumber}
                inputProps={{ maxLength: constants.visionEdiLimits.shortMediumInputLength }}
                InputLabelProps={{
                  classes: { shrink: classes.label },
                }}
              />
            </div>
            <div>
              <MainButton type="submit">{strings.next}</MainButton>
            </div>
          </form>
        )}
      </Formik>
    </Box>
  );
};

GeneralInformation.propTypes = {
  bondType: PropTypes.string,
  setBondType: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  data: PropTypes.object,
  setData: PropTypes.func,
  shipper: PropTypes.object,
  setShipper: PropTypes.func,
  deliveryCountry: PropTypes.object,
  setDeliveryCountry: PropTypes.func,
};

export default GeneralInformation;
